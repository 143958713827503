<template>
  <Layout>
    <div class="row justify-content-center">
      <div class="col-md-8 col-lg-6 col-xl-5">
        <div class="card overflow-hidden">
          <div class="bg-soft bg-primary">
            <div class="row">
              <div class="col-7">
                <div class="text-primary p-4">
                  <h5 class="text-primary">Bienvenue sur ALCIT!</h5>
                  <p>Connectez-vous au tableau de bord.</p>
                </div>
              </div>
              <div class="col-5 align-self-end">
                <img src="@/assets/images/profile-img.png" alt class="img-fluid" />
              </div>
            </div>
          </div>
          <div class="card-body pt-0">
            <div>
              <router-link to="/">
                <div class="avatar-md profile-user-wid mb-4">
                  <span class="avatar-title rounded-circle bg-light">
                    <img src="@/assets/images/logo.svg" alt height="34" />
                  </span>
                </div>
              </router-link>
            </div>
            <b-alert v-model="isAuthError" variant="danger" class="mt-3" dismissible>{{ authError }}</b-alert>

            <b-form class="p-2" @submit.prevent="tryToLogIn">
              <b-form-group class="mb-3" id="input-group-1" label="Adresse mail" label-for="input-1">
                <b-form-input id="input-1" v-model="username" type="text" placeholder="Tapez votre nom d'utilisateur" :class="{
                  'is-invalid': submitted && v$.username.$error,
                }"></b-form-input>
                <div v-for="(item, index) in v$.username.$errors" :key="index" class="invalid-feedback">
                  <span v-if="item.$message">{{ item.$message }}</span>
                </div>
              </b-form-group>

              <b-form-group class="mb-3" id="input-group-2" label="Mot de passe" label-for="input-2">
                <b-form-input id="input-2" v-model="password" type="password" placeholder="Saisir le mot de passe" :class="{
                  'is-invalid': submitted && v$.password.$error,
                }">
                </b-form-input>
                <button @click="passwordToggle('input-2')" style="position: relative;float: right;margin-top: -36px;"
                  class="btn btn-link text-decoration-none text-muted" type="button">
                  <i class="mdi mdi-eye align-middle" id="eye_icon"></i>
                </button>
                <div v-if="submitted && v$.password.$error" class="invalid-feedback">
                  <span v-if="v$.password.required.$message">
                    {{ v$.password.required.$message}}
                  </span>
                </div>
              </b-form-group>
              <div class="mt-4 d-grid">
                <b-button type="submit" variant="primary" class="btn-block" :disabled="spanView">
                  <span v-if="spanView" class="spinner-border spinner-border-sm"></span>
                  Connexion
                </b-button>
              </div>
              <div class="mt-4 text-center">
                <router-link to="/forgot-password" class="text-muted">
                  <i class="mdi mdi-lock me-1"></i> 
                   Mot de passe oublié ?
                </router-link>
              </div>
            </b-form>
          </div>
          <!-- end card-body -->
        </div>
        <!-- end card -->

        <div class="mt-5 text-center">

          <p>
            © {{ new Date().getFullYear() }} 
            Alcit. Powered by 
            <b>SIMS-TECHNOLOGIES</b>
          </p>
        </div>
        <!-- end row -->
      </div>
      <!-- end col -->
    </div>
    <!-- end row -->
  </Layout>
</template>

<script>

import Layout from "../../layouts/auth";
import { mapState } from "vuex";
import { required, helpers } from "@vuelidate/validators";
import useVuelidate from "@vuelidate/core";
import Swal from "sweetalert2";
import { reqMethods } from '../../../state/helpers';
// const jwt = require('jsonwebtoken');

/**
 * Login component
 */
export default {
  setup() {
    return { v$: useVuelidate() };
  },
  page: {
    title: "Login",
  },
  components: {
    Layout,
  },
  data() {
    return {
      username: "",
      password: "",
      submitted: false,
      authError: null,
      response: null,
      tryingToLogIn: false,
      isAuthError: false,
      spanView: false,
    };
  },
  validations: {
    username: {
      required: helpers.withMessage("Le nom d'utilisateur est obligatoire !", required),
    },
    password: {
      required: helpers.withMessage("Mot de passe obligatoire", required),
    },
  },
  computed: {
    ...mapState("authfack", ["status"]),
    notification() {
      return this.$store ? this.$store.state.notification : null;
    },
  },
  methods: {
    ...reqMethods,
    // Try to log the user in with the username
    // and password they provided.
    tryToLogIn() {
      this.submitted = true;
      this.spanView = true;
      this.v$.$touch();
      const that = this;
      this.postRequest({
        route: '/account/auth/web/token/center-agent/',
        data: { username: this.username, password: this.password }
      }).then(
        function (response) {
          that.spanView = false;
          that.response = response.data.access;
          localStorage.setItem('token', that.response);
          localStorage.setItem('nom', response.data.name);
          localStorage.setItem('prenom', response.data.first_name);
          localStorage.setItem('serial_number', response.data.serial_number);
          that.$router.push('/dashboard');
          // const hashedToken = jwt.sign(that.response,'Hs0ceB2dTdz42g');
          // localStorage.setItem('token', hashedToken);
        },
        function (error) {
          that.spanView = false;
          that.authError = error;
          Swal.fire({
              title: "Oops...",
              text: "Erreur de connexion",
              icon: "error",
              confirmButtonClass: "btn btn-primary w-xs mt-2",
              buttonsStyling: false,
              showCloseButton: true,
            });
        }
      )

    },

    passwordToggle(id) {
      var value = "password";
      var type = document.getElementById(id).getAttribute("type");
      var icon = document.getElementById("eye_icon");

      if (type === "password"){
        value = "text";
        document.getElementById(id).setAttribute("type", value);
        icon.classList.toggle("mdi-eye");
        icon.classList.toggle("mdi-eye-off");
      }else{
        value = "password";
        document.getElementById(id).setAttribute("type", value);
        icon.classList.toggle("mdi-eye-off")
        icon.classList.toggle("mdi-eye")
      }

    },
  },
  mounted() { },
};
</script>